import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Dialog } from 'chayns-api';
import ApiV5Tapp from '../tapp/tapp-content/ApiV5Tapp/ApiV5Tapp';
import { isDEVELOPMENT, isQA } from '../../constants/environments';
import { dialogEventTarget } from '../../calls/dialog/DialogHelper';

const system = {
    url: `https://tapp${(isQA || isDEVELOPMENT) ? '-dev' : ''}.chayns-static.space/api/dialog-v2/v1/v2.remoteEntry.js`,
    // url: 'https://w-lpinkernell-l.tobit.ag:8080/remoteEntry.js',
    scope: 'dialog_v2',
    module: './AppWrapper',
};

const DialogV2 = () => {
    const [dialogData, setDialogData] = useState<{ dialogs: Dialog[] } | null>({ dialogs: [] });
    const customData = useMemo(() => ({ dialogs: dialogData.dialogs }), [dialogData]);

    useEffect(() => {
        dialogEventTarget.addEventListener('change', (e) => {
            setDialogData((prev) => ({
                dialogs: [
                    ...prev.dialogs,
                    e.detail,
                ],
            }));
        });
        dialogEventTarget.addEventListener('remove', (e) => {
            setDialogData((prev) => ({
                dialogs: prev.dialogs.filter((dialog) => dialog.dialogId !== e.detail),
            }));
        });
    }, []);

    const loadTimeoutRef = useRef(null);

    const [loadDialogs, setLoadDialogs] = useState(dialogData.dialogs.length > 0);

    useEffect(() => {
        if (dialogData.dialogs.length > 0) {
            setLoadDialogs(true);
            return;
        }
        clearTimeout(loadTimeoutRef.current);
        loadTimeoutRef.current = setTimeout(() => {
            setLoadDialogs(true);
        }, 5000);

        return () => {
            clearTimeout(loadTimeoutRef.current);
        };
    }, [dialogData]);

    if (!loadDialogs) {
        return null;
    }

    return (
        <div className="dialog-v2-wrapper">
            <ApiV5Tapp
                type="client-module"
                customData={customData}
                system={system}
            />
        </div>
    );
};

export default DialogV2;
