import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { AutoSizer, InfiniteLoader, List, WindowScroller } from 'react-virtualized';
import throttle from 'lodash.throttle';
import SmallWaitCursor from '@chayns-components/SmallWaitCursor';
import { useDispatch, useSelector } from 'react-redux';
import tobitEmojione from 'tobit-emojione';

import { getDeviceInfo } from 'chayns-api';
import clsx from 'clsx';
import parseJwt from '../../../../../utils/parseJwt';
import mergeLists from '../../../../../utils/mergeLists';
import InfoCenterHeader from './info-center-header/InfoCenterHeader';
import MailItem from './mail-item/MailItem';
import GestureMenuWrapper from './gesture-menu-wrapper/GestureMenuWrapper';
import IntercomItem from './intercom-item/IntercomItem';
import { getMemberNames, getOtherMembers } from './intercom-item/utils/member';
import { MOBILE_VIEW_BREAKPOINT } from '../../../../../constants/size';
import { loadMoreMails, loadMoreSearchMails } from '../../../../../api/smart-client/get/graphQuerys';
import { INTERCOM, MAIL } from '../../../../../constants/types';
import { getAppState } from '../../../../../redux-modules/app/selector';
import { getEnvState } from '../../../../../redux-modules/env/selector';
import { getTextStringState } from '../../../../../redux-modules/text-strings/selector';
import { getUserState } from '../../../../../redux-modules/user/selector';
import { setContextMenu } from '../../../../../redux-modules/context-menu/actions';
import { getActiveServerState, getSmartClientFetchedData } from '../../../../../redux-modules/smart-client/selector';
import {
    handleDeleteMail, handleLoadData,
    handleLoadSearchMails,
    handleUpdateReadStatus,
    setMails,
} from '../../../../../redux-modules/smart-client/actions';
import { getIntercomState } from '../../../../../redux-modules/intercom/selector';
import { getSearchStringState } from '../../../../../redux-modules/search/selector';
import { handleDeleteChat, handleLoadIntercom, handleUpdateRead } from '../../../../../redux-modules/intercom/actions';
import { Thread } from '../../../../../types/thread';
import { Mail } from '../../../../../types/smartClient';
import useAnimatedToolbar from '../../../../../utils/hooks/useAnimatedToolbar';
import { setSelectedItem } from '../../../../../redux-modules/app/actions';
import appCall from '../../../../../utils/appCall';
import RefreshScroll from '../../../../util-components/RefreshScroll';
import { useMessageList } from '../../../../../utils/listDivider';
import StickyHeader from './sticky-header/StickyHeader';

const Messages: React.FC = ({ isInSpringboard, infocenter }) => {
    const dispatch = useDispatch();
    const app = useSelector(getAppState);
    const {
        colorMode,
        width,
        isSearchingMessages,
        messageSearchString,
        selectedItem,
    } = app;

    const env = useSelector(getEnvState);
    const {
        isMyChaynsApp,
        isTablet,
        isMobile,
    } = env;

    const textStrings = useSelector(getTextStringState);
    const messagesRef = useRef(null);
    const [rerender, setRerender] = useState();

    const user = useSelector(getUserState);

    const activeServer = useSelector(getActiveServerState);

    const smartClientFechtedData = useSelector(getSmartClientFetchedData);
    const intercom = useSelector(getIntercomState);
    const searchString = useSelector(getSearchStringState);

    const [scrollValue, setScrollValue] = useState(0);

    const showLoadingAnimation = isSearchingMessages || !intercom.fetchedData;

    const searchStringToUse = infocenter ? messageSearchString : (width >= MOBILE_VIEW_BREAKPOINT) ? messageSearchString : searchString;

    const mails = !searchStringToUse ? activeServer?.emailConnection?.values : activeServer?.searchMails?.values;
    const skipToken = !searchStringToUse ? activeServer?.emailConnection?.skipToken : activeServer?.searchMails?.skipToken;

    useEffect(() => {
        tobitEmojione.ascii = true;
        tobitEmojione.imageTitleTag = false;
        tobitEmojione.imagePathPNG = 'https://api.chayns-static.space/emojione/4.0/png/64/';
        tobitEmojione.blacklistChars = '*,#,®,™,©,0,1,2,3,4,5,6,7,8,9';
    }, []);

    const threads = useMemo(() => {
        if (!searchStringToUse) {
            return intercom?.threads;
        }
        const searchToLower = searchStringToUse.toLowerCase();
        return intercom?.threads.filter((thread) => {
            const { members, name } = thread;
            const otherMembers = getOtherMembers(members, user.userId);
            const memberNames = getMemberNames(otherMembers, {
                shouldGetFullNames: otherMembers.length < 3,
            });
            return (name || '').toLowerCase().includes(searchToLower) || (memberNames || '').toLowerCase().includes(searchToLower);
        });
    }, [intercom?.threads, searchStringToUse, user.userId]);

    const [isFetching, setIsFetching] = useState(false);
    const { list, itemCount: count } = useMessageList(mails, threads, showLoadingAnimation, isInSpringboard, infocenter);

    const isItemLoaded = useCallback((index) => !!list[index], [list]);

    const tokenJti = useMemo(() => parseJwt(user?.token), [user?.token]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleLoad = useCallback(throttle(async ({ stopIndex }) => {
        if (!isFetching && skipToken && activeServer && !list[stopIndex]) {
            setIsFetching(true);
            if (!searchStringToUse) {
                try {
                    const {
                        status,
                        result,
                    } = await loadMoreMails(activeServer.endpoint, skipToken, 'isRead eq 0', user?.token);
                    if (status === 200 && result?.data?.emailConnection?.values) {
                        const { emailConnection } = result.data;
                        dispatch(setMails({
                            emailConnection,
                        }));
                    }
                } catch (error) {
                    setIsFetching(false);
                }
            } else {
                try {
                    const {
                        status,
                        result,
                    } = await loadMoreSearchMails(activeServer.endpoint, searchStringToUse, skipToken, user?.token);
                    if (status === 200 && result?.data?.emailConnection?.values) {
                        const { emailConnection } = result.data;
                        dispatch(handleLoadSearchMails({
                            emailConnection,
                            searchString: searchStringToUse,
                        }));
                    }
                } catch (error) {
                    setIsFetching(false);
                }
            }
            setIsFetching(false);
        }
    }, 200, { leading: false }), [mails, isFetching, activeServer?.endpoint, user]);

    useAnimatedToolbar({
        enabled: app.userAgent.includes(' team/') && messagesRef.current?.querySelector('.ReactVirtualized__Grid'),
        addMinHeight: false,
        scrollContainer: messagesRef.current?.querySelector('.ReactVirtualized__Grid'),
        onChange: () => {
        },
    });

    const itemCount = app.userAgent.includes(' team/') ? ((!skipToken ? count : (count || 0) + 1) + 2) : (!skipToken ? count : (count || 0) + 1); // idk why +2

    const backgroundColor = (() => {
        if (!isMyChaynsApp) {
            if (width >= MOBILE_VIEW_BREAKPOINT) {
                return colorMode === 'light' ? 'white' : '#1E1E1E';
            }
            return colorMode === 'light' ? '#f7f7f7' : '#121212';
        }

        if (isInSpringboard && isMobile) {
            return colorMode === 'light' ? '#f7f7f7' : 'black';
        }

        if (isInSpringboard && isInSpringboard) {
            return colorMode === 'light' ? '#f7f7f7' : '#121212';
        }
        return 'unset';
    })();

    const listElement = useCallback(({ index, key, style }) => {
        const item = list[index];
        if (!item) {
            if (app.userAgent.includes(' team/')) {
                return <div key={key} style={style}/>;
            }
            return null;
        }

        if (item.id === 'today') {
            return null;
        }

        if (item.isPlaceholder) {
            return (
                <div style={style} key={item.id} className={clsx('animate-top', 'view--list--divider', colorMode !== 'dark' && 'chayns__color--205')}>
                    {item.id}
                </div>
            );
        }

        const gestureMenuItemsMails = {
            left: [
                {
                    callback: () => {
                        setTimeout(() => {
                            dispatch(handleUpdateReadStatus({ ...item, isRead: !item.isRead } as Mail));
                        }, 600);
                    },
                    color: '#FFFFFF',
                    backgroundColor: '#404040',
                    icon: 'fa fa-check',
                    text: 'Gelesen',
                },
            ],
            right: [
                {
                    callback: () => {
                        dispatch(setContextMenu({
                            id: item.id,
                            isList: true,
                            contextType: MAIL,
                            x: 0,
                            y: 0,
                        }));
                    },
                    color: '#FFFFFF',
                    backgroundColor: '#404040',
                    icon: 'ts-ellipsis_v',
                    text: 'Mehr',
                },
                {
                    callback: () => {
                        setTimeout(() => {
                            dispatch(handleDeleteMail({
                                id: item.id,
                                subject: item.id,
                                skipDialog: true,
                            }));
                        }, 600);
                    },
                    color: '#FFFFFF',
                    backgroundColor: '#CC0000',
                    icon: 'fa fa-trash',
                    text: 'Löschen',
                },
            ],
        };

        const useGestureMenu = getDeviceInfo(navigator.userAgent, '').isTouch;

        if (item.type === MAIL) {
            return (
                <div
                    style={style}
                    key={key}
                >
                    {useGestureMenu
                        ? (
                            <GestureMenuWrapper menuItems={gestureMenuItemsMails}>
                                <MailItem
                                    item={item as Mail}
                                    endpoint={activeServer?.endpoint}
                                    tokenJti={tokenJti}
                                    infocenter={infocenter}
                                    isInSpringBoard={isInSpringboard}
                                />
                            </GestureMenuWrapper>
                        ) : (
                            <MailItem
                                item={item as Mail}
                                endpoint={activeServer?.endpoint}
                                tokenJti={tokenJti}
                                isInSpringBoard={isInSpringboard}
                                infocenter={infocenter}
                            />
                        )}
                </div>
            );
        }

        const gestureMenuItemsIntercom = {
            left: [
                {
                    callback: () => {
                        setTimeout(() => {
                            dispatch(handleUpdateRead({
                                thread: item as Thread,
                            }));
                        }, 600);
                    },
                    color: '#FFFFFF',
                    backgroundColor: '#404040',
                    icon: 'fa fa-check',
                    text: 'Gelesen',
                },
            ],
            right: [
                {
                    callback: () => {
                        dispatch(setContextMenu({
                            id: item.id,
                            isList: true,
                            contextType: INTERCOM,
                            x: 0,
                            y: 0,
                        }));
                    },
                    color: '#FFFFFF',
                    backgroundColor: '#404040',
                    icon: 'ts-ellipsis_v',
                    text: 'Mehr',
                },
                {
                    callback: () => {
                        setTimeout(() => {
                            dispatch(handleDeleteChat({
                                thread: item as Thread,
                            }));
                        }, 600);
                    },
                    color: '#FFFFFF',
                    backgroundColor: '#CC0000',
                    icon: 'fa fa-trash',
                    text: 'Löschen',
                },
            ],
        };

        return (
            <div
                style={style}
                key={item.id}
                className="cc__list"
            >
                {useGestureMenu
                    ? (
                        <GestureMenuWrapper menuItems={gestureMenuItemsIntercom}>
                            <IntercomItem
                                isInSpringBoard={isInSpringboard}
                                thread={item}
                            />
                        </GestureMenuWrapper>
                    ) : (
                        <IntercomItem
                            isInSpringBoard={isInSpringboard}
                            thread={item}
                        />
                    )}

            </div>
        );
    }, [list, isInSpringboard, app.userAgent, colorMode, dispatch, activeServer?.endpoint, tokenJti, infocenter]);

    const handleSetListRef = useCallback((ref) => {
        messagesRef.current = ref;
    }, []);

    const handleLoadRefList = useCallback(() => {
        setRerender({});
    }, []);

    useEffect(() => {
        if (!showLoadingAnimation) {
            appCall(297);
        }
    }, [showLoadingAnimation]);

    if (isInSpringboard && list.length === 0) return null;

    const content = (
        <>
            <div
                ref={handleSetListRef}
                className={classNames('messages-wrapper', {
                    'mobile-view': width < MOBILE_VIEW_BREAKPOINT,
                })}
                style={{
                    height: '100%',
                    backgroundColor,
                    marginTop: isInSpringboard ? null : ((width < MOBILE_VIEW_BREAKPOINT && !infocenter) ? 8 : 0),
                }}
            >
                {(width >= MOBILE_VIEW_BREAKPOINT || (infocenter)) && smartClientFechtedData && intercom.fetchedData && (
                    <InfoCenterHeader infocenter={infocenter}/>
                )}

                {list.length > 0 && (
                    <div className="messages-wrapper__loader">
                        <InfiniteLoader
                            isRowLoaded={isItemLoaded}
                            loadMoreRows={handleLoad}
                            rowCount={itemCount}
                            ref={handleLoadRefList}
                        >
                            {({ onRowsRendered, registerChild }) => {
                                if (width < MOBILE_VIEW_BREAKPOINT && !infocenter) {
                                    return (
                                        <WindowScroller onScroll={({ scrollTop }) => setScrollValue(scrollTop)}>
                                            {({
                                                height: windowScrollerHeight,
                                                isScrolling,
                                                scrollTop,
                                                onChildScroll,
                                            }) => ((
                                                <List
                                                    ref={registerChild}
                                                    autoHeight
                                                    height={windowScrollerHeight || 500}
                                                    width={isInSpringboard ? (width * 0.9) : width}
                                                    onRowsRendered={onRowsRendered}
                                                    isScrolling={isScrolling}
                                                    rowCount={itemCount}
                                                    onScroll={onChildScroll}
                                                    rowHeight={64}
                                                    rowRenderer={listElement}
                                                    scrollTop={scrollTop}
                                                    overscanRowCount={20}
                                                    style={{
                                                        backgroundColor,
                                                        paddingBottom: !isInSpringboard && !isMyChaynsApp ? '70px' : 0,
                                                    }}
                                                />
                                            )
                                            )}
                                        </WindowScroller>
                                    );
                                }
                                return (
                                    <AutoSizer>
                                        {({ height, width: autoSizerWidth }) => {
                                            let calcWidth = autoSizerWidth;
                                            if (!calcWidth) {
                                                if (width * 0.2 <= 250) {
                                                    calcWidth = 250;
                                                } else if (width * 0.2 >= 420) {
                                                    calcWidth = 420;
                                                } else {
                                                    calcWidth = Math.round(width * 0.2);
                                                }
                                            }
                                            return (
                                                <List
                                                    height={height}
                                                    ref={registerChild}
                                                    width={calcWidth}
                                                    onScroll={({ scrollTop }) => setScrollValue(scrollTop)}
                                                    onRowsRendered={onRowsRendered}
                                                    rowCount={itemCount}
                                                    rowHeight={64}
                                                    rowRenderer={listElement}
                                                    overscanRowCount={1}
                                                    style={{
                                                        backgroundColor,
                                                        overflowX: 'auto',
                                                    }}
                                                    className="scrollbar"
                                                />
                                            );
                                        }}
                                    </AutoSizer>
                                );
                            }}
                        </InfiniteLoader>

                    </div>
                )}

                {showLoadingAnimation && !isInSpringboard && (
                    <div
                        className="message-wait-cursor"
                    >
                        <SmallWaitCursor show/>
                    </div>
                )}

                {list.length === 0 && !showLoadingAnimation && (
                    <p
                        className="chayns__color--text"
                        style={{
                            textAlign: 'center',
                            marginTop: '20px',
                        }}
                    >
                        {textStrings?.['txt_chayns_de_no_messages'] || 'Keine Nachrichten'}
                    </p>
                )}

                <StickyHeader scrollValue={scrollValue} list={list}/>
            </div>
            <style jsx global>
                {`
                    .messages-wrapper {
                        width: ${infocenter ? '100%;' : '20%;'};
                        min-width: 250px;
                        max-width: ${infocenter ? '' : '420px;'};
                        position: relative;
                        z-index: 1;
                        height: ${infocenter ? '100%' : 'auto !important;'};
                        overflow: hidden;
                        box-shadow: ${isMobile && isInSpringboard ? 'rgba(0, 0, 0, 0.1) 1px 2px 4px' : null};
                        display: flex;
                        flex-direction: column;
                        
                        .animate-top {
                            display: flex;
                            transition: top 400ms ease-out, height 400ms ease-out;
                        }
                        
                        .view--list--divider {
                            padding: ${isMobile ? '30px 22px 0' : '30px 10px 0'};
                            text-align: left;
                            ${colorMode === 'dark' ? 'color: #d4d4d4;' : ''}
                        }

                        &__loader {
                            height: 100%;
                            flex-grow: 1;
                            
                            >div>::-webkit-scrollbar {
                                width: 0;
                            }
                        }

                        &:not(:empty) {
                            margin-top: ${isInSpringboard ? '20px' : ''};
                            margin-bottom: ${isInSpringboard ? '20px' : ''};
                        }

                        &.mobile-view {
                            width: 100%;
                            margin-left: 0;
                            border-right: none;
                            max-width: unset;
                        }
                        
                        &:not(.mobile-view) {
                            &:after {
                                content: '';
                                position: absolute;
                                top: 0;
                                right: 0;
                                bottom: 0;
                                border-right: 1px solid ${colorMode === 'dark' ? 'rgba(255, 255, 255, .15)' : 'rgba(0, 0, 0, 0.15)'};
                            }
                        }

                        .info-center-headline {
                            margin: 10px 0 9px 9px;
                        }

                        .list-mobile-view {
                            overflow: hidden !important;
                            margin-top: 5px;
                        }

                        .message-wait-cursor {
                            justify-content: center;
                            align-items: center;
                            display: flex;
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                        }
                    }
                `}
            </style>
        </>
    );

    if (infocenter) {
        return (
            <RefreshScroll
                onRefresh={async () => {
                    const tasks = [dispatch(handleLoadData(activeServer)), dispatch(handleLoadIntercom({ take: 1000 }))];
                    await Promise.all(tasks);
                    setScrollValue(0);
                }}
                isDisabled={scrollValue !== 0}
            >
                {content}
            </RefreshScroll>

        );
    }

    return content;
};

Messages.displayName = 'Messages';

export default memo(Messages);
